<template>
    <div class="app-wrap">
        <AppLeftSidebar />
        <NuxtPage class="main-full" />
    </div>
</template>

<script setup lang="ts">
import AppLeftSidebar from '~/components/AppLeftSidebar.vue'
import { useAppTheme } from '~/composables/useAppTheme'

useAppTheme()
</script>

<style>
   .main-full {
    flex-grow: 1;
    margin-left: 18px;
    padding: 20px;
}
</style>
